@use "../config/" as *;

.waf-fixtures {
    @extend %px-2;
    .status,.short-name,.match-status,.graph-box,.run-rate,.countdown-venue-wrapper {
        @extend %d-none;
    }
    .waf-head {
        .head-tab {
            @extend %d-none;
        }
        .head-wrap {
            @extend %gap-6;
        }
    }
    .tab {
        &s {
            width: calc(100% - 6rem);
            @extend %my-4;
            @extend %flex;
            @extend %gap-3;
            &.primary-tabs {
                @extend %transparent-bg;
            }
        }
        &-name {
            flex: 1;
            height: 4.4rem;
            &.active {
                .tab-anchor {
                    border: .1rem solid var(--tertiary-600);
                    @extend %tertiary-600-bg;
                    .btn-text {
                        font-weight: 700;
                        @extend %secondary-800;
                    }
                }
            }
        }
        &-anchor {
            border: .1rem solid var(--secondary-800);
            @extend %w-100;
            @extend %h-100;
            @extend %half-radius;
            .btn-text {
                @extend %capitalize;
                @extend %neutral-800;
                @extend %font-14-pm;
            }
        }
    }
    .card {
        &-head {
            flex-wrap: wrap;
            @extend %flex-c-c;
        }
        &-number {
            .text,
            .number {
                @extend %neutral-700;
                @extend %capitalize;
                @extend %font-14-pb;
            }
        }
        &-report {
            @extend %d-none;
        }
        &-list {
            @extend %gap-5;
            @extend %flex-column;
        }
        &-item {
            height: 36.5rem;
            @extend %w-100;
            @extend %flex-column;
            @extend %relative;
            @extend %p-4-2;
            @extend %half-radius;
            &::after {
                content: '';
                border: .1rem solid clr(neutral-800, 2);
                z-index: var(--z-scorestrip-border);
                mask-image: linear-gradient(var(--neutral-200) 60%, transparent);
                pointer-events: none;
                @extend %half-radius;
                @extend %pos-tl;
                @extend %w-100;
                @extend %h-100;
                @extend %neutral-200-bg;
            }
        }
        &-footer {
            height: 10rem;
            @extend %flex-column-sb-n;
            &-text {
                @extend %text-center;
                @extend %neutral-700;
                @extend %font-14-pm;
            }
            &:before {
                content: '';
                height: .1rem;
                pointer-events: none;
                width: calc(100% - var(--space-4));
                background: linear-gradient(90deg, clr(neutral-800, 0) 0%, clr(neutral-800, 5) 24.4%, var(--neutral-800) 47.45%, clr(neutral-800, 4) 76.68%, clr(neutral-800, 0) 99.74%);
                @include position(null,null,13rem,var(--space-2));
            }
        }
        &-venue {
            font-weight: 500;
            width: calc(100% - var(--space-4));
            @extend %text-center;
            @extend %neutral-700;
            @extend %capitalize;
            @include truncate(2, 14);
            @include position(var(--space-9),null,null,var(--space-2));
        }
        &-body {
            @extend %mt-14;
        }
        &-content {
            height: 13.5rem;
            @extend %relative;
            &:before {
                content: '';
                height: .1rem;
                @extend %w-100;
                background: linear-gradient(90deg, clr(neutral-800, 0) 0%, clr(neutral-800, 5) 24.4%, var(--neutral-800) 47.45%, clr(neutral-800, 4) 76.68%, clr(neutral-800, 0) 99.74%);
                @include position(var(--space-3-neg),null,null,0);
            }
        }
    }
    .team {
        width: 45%;
        @extend %gap-1;
        @extend %flex;
        &-wrapper {
            @extend %flex-sb-n;
        }
        &-b {
            flex-direction: row-reverse;
            .team-info {
                align-items: flex-end;
                @extend %text-right;
            }
            .team-data {
                @extend %text-right;
            }
        }
        &-info {
            width: 5rem;
            @extend %gap-2;
            @extend %flex-column;
        }
        &-image {
            width: 5rem;
            height: 5rem;
            flex-shrink: 0;
            @extend %circle-radius;
            @extend %neutral-50-bg-5;
            @extend %p-1;
            @extend %flex-c-c;
        }
        &-name {
            width: 12rem;
            @extend %capitalize;
            @extend %neutral-800;
            @extend %font-12-pb;
        }
        &-won,&-current {
            .score {
                @extend %accent-900;
            }
        }
    }
    .date-time {
        padding-left: 1.2rem;
        @extend %relative;
        &:before {
            content: '';
            left: var(--space-1);
            width: .2rem;
            @extend %h-80;
            @extend %neutral-700-bg;
            @extend %pos-y-center;
        }
        .meta {
            @extend %neutral-700;
            @extend %capitalize;
            @extend %font-14-pb;
        }
    }
    .match-time {
        width: 10%;
        isolation: isolate;
        @extend %relative;
        @extend %flex-c-c;
        &:before {
            content: '';
            width: .1rem;
            pointer-events: none;
            background: linear-gradient(180deg, clr(neutral-800, 0) 0%, clr(neutral-800, 5) 24.4%, var(--neutral-800) 47.45%, clr(neutral-800, 4) 76.68%, clr(neutral-800, 0) 99.74%);
            top: 0;
            z-index: var(--z-scorestrip-border);
            @extend %h-100;
            @extend %pos-x-center;
        }
    }
    .time-text {
        border-radius: .3rem;
        padding: .3rem;
        border-radius: .3rem;
        width: max-content;
        @extend %accent-900-bg;
        @extend %font-12-pb;
        @extend %uppercase;
        @extend %pure-white-900;
    }
    .btn-group {
        @extend %mt-2;
        .btn-more {
            border: .1rem solid var(--secondary-800);
            height: 4.8rem;
            transition: background-color .3s linear;
            @extend %flex-c-c;
            @extend %w-100;
            @extend %half-radius;
            .btn-text {
                @extend %secondary-800;
                @extend %font-14-pb;
                @extend %uppercase;
            }
            &:hover {
                border-color: var(--tertiary-600);
                @extend %tertiary-600-bg;
            }
        }
    }
    .score {
        @extend %font-16-pb;
        @extend %neutral-700;
        &.no-score {
            @extend %accent-900;
        }
    }
    .overs {
        @extend %font-12-pm;
        @extend %neutral-700;
    }
    .recent {
        .score,.overs {
            opacity: .2;
        }
        .team-won {
            .score,.overs {
                opacity: 1;
            }
        }
    }
    .card-item {
        &.super-overs {
            .team-won,.team-current {
                .team-data {
                    &:not(:last-child) {
                        .score,.overs {
                            opacity: .2;
                            @extend %neutral-700;
                        }
                    }
                    &:last-child {
                        .score,.overs {
                            opacity: 1;
                        }
                    }
                }
            }
            .score,.overs {
                opacity: .2;
            }
        }
    }
    .super-overs {
        .team {
            &-data {
                @extend %gap-1;
                @extend %flex;
            }
            &-b {
                .team-data {
                    flex-direction: row-reverse;
                }
            }
        }
        .super-over {
            .score {
                font-size: 1.4rem;
            }
            .overs {
                font-size: 1.2rem;
            }
        }
    }
    .no-data {
        border: .1rem solid var(--secondary-800);
        @extend %my-2;
        @extend %py-3;
        @extend %secondary-800;
        @extend %uppercase;
        @extend %font-14-pb;
        @extend %half-radius;
        @extend %flex-c-c;
    }
    &.waf-shimmer {
        .card {
            &-head {
                height: 2.2rem;
                @include shimmer;
            }
            &-number {
                .number,.text {
                    @extend %font-0;
                }
            }
            &-venue {
                height: 4.2rem;
                @extend %font-0;
                @include shimmer;
                position: absolute;
            }
            &-footer-text {
                width: 100%;
                height: 3.6rem;
                @extend %font-0;
                @include shimmer;
            }
        }
        .date-time {
            .meta {
                @extend %font-0;
            }
        }
        .score {
            width: 5.5rem;
            height: 1.9rem;
            @extend %font-0;
            @include shimmer;
        }
        .overs {
            width: 3.7rem;
            height: 1.5rem;
            @include shimmer;
            @extend %font-0;
        }
        .team-name {
            width: 12rem;
            height: 3rem;
            @extend %font-0;
            @include shimmer;
            &.short-name {
                @extend %d-none;
            }
        }
        .btn-more {
            border: 0;
            @include shimmer;
            .btn-text {
                @extend %font-0;
            }
        }
    }
}
@media screen and (max-width: 350px) {
    .waf-fixtures {
        .tabs {
            gap: var(--space-2);
            width: calc(100% - 5rem);
        }
    }
}
@include mq(col-md) {
    .waf-fixtures {
        .tabs {
            width: 52rem;
        }
        .card {
            &-list {
                flex-wrap: wrap;
                flex-direction: row;
                column-gap: 15px;
                row-gap: 20px;
            }
            &-item {
                height: 40rem;
                width: calc(50% - .8rem);
            }
            &-footer {
                &::before {
                    bottom: 16rem;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-fixtures {
        .tabs {
            width: 62rem;
        }
        .card {
            &-item {
                height: 40rem;
                width: calc(33.33% - var(--space-2));
            }
            &-content {
                height: 17rem;
            }
            &-footer {
                &::before {
                    bottom: 13rem;
                }
            }
        }
        .team {
            gap: var(--space-2);
            &-info {
                width: 8rem;
            }
            &-name {
                font-size: 1.4rem;
                width: 14rem;
            }
            &-image {
                width: 8rem;
                height: 8rem;
                padding: var(--space-2);
            }
            &-data-wrap {
                height: 8rem;
                @include flex-config(flex,column,center);
            }
        }
        .score {
            font-size: 1.8rem;
        }
        &.waf-shimmer {
            .team-name {
                width: 14rem;
                height: 3.6rem;
            }
            .score {
                width: 6.2rem;
                height: 2.2rem;
            }
        }
    }
}